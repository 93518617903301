import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import Form from "./Form";
import {
  Button,
  Spacer,
  Radio,
  CircularProgress,
  Modal,
  Link,
  Image,
  ModalHeader,
  ModalBody,
  RadioGroup,
  Snippet,
  useDisclosure,
  ModalContent,
  Input,
} from "@nextui-org/react";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
} from "@nextui-org/react";
import PayPal from "./PayPal";

const product = [
  {
    id: 2,
    platform: "Twitch",
    period: "hour",
    desc: "Twitch Hour Viewers",
    titlemain: "One-Hour Twitch Viewers",
    price: 0.007,
    wei: "8000000000000",
    min: 5,
    max: 5000,
    img: "./twitchhour.png",
    title: "Twitch Username",
    placeholder: "riotgames",
    placeholder2: "Enter viewer count",
    about: [
      "Fast Start: 0-12 minutes",
      "Targeting: Worldwide",
      "Viewers will be shown in userlist",
    ],
  },
  {
    id: 3,
    platform: "Twitch",
    period: "day",
    desc: "Twitch Day Viewers",
    titlemain: "One-Day Twitch Viewers",
    price: 0.035,
    wei: "40000000000000",
    min: 5,
    max: 5000,
    img: "./twitchday.png",
    title: "Twitch Username",
    placeholder: "riotgames",
    placeholder2: "Enter viewer count",
    about: [
      "Fast Start: 0-12 minutes",
      "Targeting: Worldwide",
      "Viewers will be shown in userlist",
    ],
  },
  {
    id: 4,
    platform: "Twitch",
    period: "week",
    desc: "Twitch Week Viewers",
    titlemain: "One-Week Twitch Viewers",
    price: 0.2,
    wei: "250000000000000",
    min: 5,
    max: 5000,
    img: "./twitchweek.png",
    title: "Twitch Username",
    placeholder: "riotgames",
    placeholder2: "Enter viewer count",
    about: [
      "Fast Start: 0-12 minutes",
      "Targeting: Worldwide",
      "Viewers will be shown in userlist",
    ],
  },
  {
    id: 5,
    platform: "Twitch",
    period: "month",
    desc: "Twitch Month Viewers",
    titlemain: "One-Month Twitch Viewers",
    price: 0.61,
    wei: "700000000000000",
    min: 5,
    max: 5000,
    img: "./twitchmonth.png",
    title: "Twitch Username",
    placeholder: "riotgames",
    placeholder2: "Enter viewer count",
    about: [
      "Fast Start: 0-12 minutes",
      "Targeting: Worldwide",
      "Viewers will be shown in userlist",
    ],
  },
  {
    id: 6,
    platform: "Twitch",
    period: "followers",
    desc: "Twitch Followers",
    titlemain: "Instant Twitch Followers",
    price: 0.0084,
    wei: "4000000000000",
    min: 5,
    max: 4000,
    img: "./twitchfollow.png",
    title: "Twitch Username",
    placeholder: "riotgames",
    placeholder2: "Enter followers count",
    about: [
      "Fast Start: 0-5 minutes",
      "Targeting: Worldwide",
      "Guarantee: 365 days",
    ],
  },
  {
    id: 7,
    platform: "Kick",
    period: "hour",
    desc: "Kick Hour Viewers",
    titlemain: "One-Hour Kick Viewers",
    price: 0.009,
    wei: "9500000000000",
    min: 10,
    max: 5000,
    img: "./kickhour.png",
    title: "Kick username",
    placeholder: "dogehype",
    placeholder2: "Enter viewer count",
    about: [
      "Fast Start: 0-12 minutes",
      "Targeting: Worldwide",
      "Viewers will be shown in userlist",
    ],
  },
  {
    id: 8,
    platform: "Kick",
    period: "day",
    desc: "Kick Day Viewers",
    titlemain: "One-Day Kick Viewers",
    price: 0.05,
    wei: "9500000000000",
    min: 5,
    max: 5000,
    img: "./kickday.png",
    title: "Kick username",
    placeholder: "dogehype",
    placeholder2: "Enter viewer count",
    about: [
      "Fast Start: 0-12 minutes",
      "Targeting: Worldwide",
      "Viewers will be shown in userlist",
    ],
  },
  {
    id: 9,
    platform: "Kick",
    period: "week",
    desc: "Kick Week Viewers",
    titlemain: "One-Week Kick Viewers",
    price: 0.16,
    wei: "9500000000000",
    min: 5,
    max: 5000,
    img: "./kickweek.png",
    title: "Kick username",
    placeholder: "dogehype",
    placeholder2: "Enter viewer count",
    about: [
      "Fast Start: 0-12 minutes",
      "Targeting: Worldwide",
      "Viewers will be shown in userlist",
    ],
  },
  {
    id: 10,
    platform: "Kick",
    period: "month",
    desc: "Kick Month Viewers",
    titlemain: "One-Month Kick Viewers",
    price: 0.43,
    wei: "9500000000000",
    min: 5,
    max: 5000,
    img: "./kickmonth.png",
    title: "Kick username",
    placeholder: "dogehype",
    placeholder2: "Enter viewer count",
    about: [
      "Fast Start: 0-12 minutes",
      "Targeting: Worldwide",
      "Viewers will be shown in userlist",
    ],
  },
  {
    id: 11,
    platform: "Kick",
    period: "followers",
    desc: "Kick Followers",
    titlemain: "Kick Followers",
    price: 0.004,
    wei: "9500000000000",
    min: 10,
    max: 20000,
    img: "./kickfollow.png",
    title: "Kick username",
    placeholder: "dogehype",
    placeholder2: "Enter followers count",
    about: [
      "Fast Start: 0-5 minutes",
      "Targeting: Worldwide",
      "Guarantee: 365 days",
    ],
  },
  {
    id: 12,
    platform: "Tiktok",
    period: "views",
    desc: "Tiktok Views",
    titlemain: "TikTok Views",
    price: 0.0001,
    wei: "9500000000000",
    min: 20,
    max: 500000,
    img: "./DogeTikTok.png",
    title: "TikTok username",
    placeholder: "ZDOXn5G8E",
    placeholder2: "Enter views count",
    about: ["Works for shorts", "Speed: 100-300k/day", "Non drop"],
  },
  {
    id: 13,
    platform: "Tiktok",
    period: "followers",
    desc: "Tiktok Followers",
    titlemain: "TikTok Followers",
    price: 0.006,
    wei: "9500000000000",
    min: 20,
    max: 50000,
    img: "./DogeTikTok.png",
    title: "TikTok username",
    placeholder: "@name",
    placeholder2: "Enter followers count",
    about: ["Avatars Followers", "Speed: 2k-5K/day", "Non drop"],
  },
  {
    id: 14,
    platform: "Tiktok",
    period: "likes",
    desc: "Tiktok Likes",
    titlemain: "TikTok Likes",
    price: 0.001,
    wei: "9500000000000",
    min: 20,
    max: 50000,
    img: "./DogeTikTok.png",
    title: "TikTok username",
    placeholder: "ZDOXn5G8E",
    placeholder2: "Enter likes count",
    about: ["Instant", "Speed: 3-15k/day", "Non drop"],
  },
  {
    id: 18,
    platform: "Youtube",
    period: "views",
    desc: "Youtube Views",
    titlemain: "Youtube Views",
    price: 0.002,
    wei: "12EAE09C800",
    min: 500,
    max: 1000000,
    img: "./DogeYoutube.png",
    title: "Video ID",
    placeholder: "6DOGEjSTVBs",
    placeholder2: "Enter views count",
    about: ["Works for shorts", "Speed: 100-300k/day", "Non drop"],
  },
  {
    id: 19,
    platform: "Youtube",
    period: "followers",
    desc: "Youtube Subs",
    titlemain: "Youtube Subscribers",
    price: 0.02,
    wei: "9500000000000",
    min: 50,
    max: 100000,
    img: "./DogeYoutube.png",
    title: "Channel ID",
    placeholder: "channel/***",
    placeholder2: "Enter subs count",
    about: ["Start: 0-24 hours", "Speed: 100-600/Day", "Non drop"],
  },
  {
    id: 20,
    platform: "Youtube",
    period: "likes",
    desc: "Youtube Likes",
    titlemain: "Youtube Likes",
    price: 0.003,
    wei: "9500000000000",
    min: 10,
    max: 100000,
    img: "./DogeYoutube.png",
    title: "Video ID",
    placeholder: "6DOGEjSTVBs",
    placeholder2: "Enter likes count",
    about: ["Start: 0-24 hours", "Speed: 10-40k/day", "Non drop"],
  },
  {
    id: 21,
    platform: "Trovo",
    period: "hour",
    desc: "Trovo Hour Viewers",
    titlemain: "One-Hour Trovo Viewers",
    price: 0.004,
    wei: "8000000000000",
    min: 10,
    max: 5000,
    img: "./trovohour.png",
    title: "Trovo Username",
    placeholder: "riotgames",
    placeholder2: "Enter viewer count",
    about: [
      "Fast Start: 0-12 minutes",
      "Targeting: Worldwide",
      "Viewers will be shown in userlist",
    ],
  },
  {
    id: 22,
    platform: "Trovo",
    period: "day",
    desc: "Trovo Day Viewers",
    titlemain: "One-Day Trovo Viewers",
    price: 0.02,
    wei: "40000000000000",
    min: 5,
    max: 5000,
    img: "./trovoday.png",
    title: "Trovo Username",
    placeholder: "riotgames",
    placeholder2: "Enter viewer count",
    about: [
      "Fast Start: 0-12 minutes",
      "Targeting: Worldwide",
      "Viewers will be shown in userlist",
    ],
  },
  {
    id: 23,
    platform: "Trovo",
    period: "week",
    desc: "Trovo Week Viewers",
    titlemain: "One-Week Trovo Viewers",
    price: 0.1,
    wei: "250000000000000",
    min: 5,
    max: 5000,
    img: "./trovoweek.png",
    title: "Trovo Username",
    placeholder: "riotgames",
    placeholder2: "Enter viewer count",
    about: [
      "Fast Start: 0-12 minutes",
      "Targeting: Worldwide",
      "Viewers will be shown in userlist",
    ],
  },
  {
    id: 24,
    platform: "Trovo",
    period: "month",
    desc: "Trovo Month Viewers",
    titlemain: "One-Month Trovo Viewers",
    price: 0.3,
    wei: "700000000000000",
    min: 5,
    max: 5000,
    img: "./trovomonth.png",
    title: "Trovo Username",
    placeholder: "riotgames",
    placeholder2: "Enter viewer count",
    about: [
      "Fast Start: 0-12 minutes",
      "Targeting: Worldwide",
      "Viewers will be shown in userlist",
    ],
  },
  {
    id: 25,
    platform: "Trovo",
    period: "followers",
    desc: "Twitch Followers",
    titlemain: "Instant Trovo Followers",
    price: 0.004,
    wei: "4000000000000",
    min: 50,
    max: 4000,
    img: "./trovofollow.png",
    title: "Trovo Username",
    placeholder: "riotgames",
    placeholder2: "Enter followers count",
    about: [
      "Fast Start: 0-5 minutes",
      "Targeting: Worldwide",
      "Guarantee: 365 days",
    ],
  },
  {
    id: 26,
    platform: "Naver",
    period: "hour",
    desc: "Naver Hour Viewers",
    titlemain: "One-Hour Naver Viewers",
    price: 0.0011,
    wei: "250000000000000",
    min: 10,
    max: 4000,
    img: "./trovoweek.png",
    title: "Naver Url",
    placeholder: "https://chzzk.naver.com/live/***",
    placeholder2: "Enter viewer count",
    about: [
      "Fast Start: 0-12 minutes",
      "Targeting: Worldwide",
      "Viewers will be shown in userlist",
    ],
  },
  {
    id: 27,
    platform: "Naver",
    period: "day",
    desc: "Naver Day Viewers",
    titlemain: "One-Day Naver Viewers",
    price: 0.011,
    wei: "250000000000000",
    min: 10,
    max: 4000,
    img: "./trovoweek.png",
    title: "Naver Url",
    placeholder: "https://chzzk.naver.com/live/***",
    placeholder2: "Enter viewer count",
    about: [
      "Fast Start: 0-12 minutes",
      "Targeting: Worldwide",
      "Viewers will be shown in userlist",
    ],
  },
  {
    id: 28,
    platform: "Naver",
    period: "week",
    desc: "Naver Week Viewers",
    titlemain: "One-Week Naver Viewers",
    price: 0.043,
    wei: "700000000000000",
    min: 10,
    max: 4000,
    img: "./trovomonth.png",
    title: "Naver Url",
    placeholder: "https://chzzk.naver.com/live/***",
    placeholder2: "Enter viewer count",
    about: [
      "Fast Start: 0-12 minutes",
      "Targeting: Worldwide",
      "Viewers will be shown in userlist",
    ],
  },
  {
    id: 29,
    platform: "Naver",
    period: "month",
    desc: "Naver Month Viewers",
    titlemain: "One-Month Naver Viewers",
    price: 0.17,
    wei: "700000000000000",
    min: 10,
    max: 4000,
    img: "./trovomonth.png",
    title: "Naver Url",
    placeholder: "https://chzzk.naver.com/live/***",
    placeholder2: "Enter viewer count",
    about: [
      "Fast Start: 0-12 minutes",
      "Targeting: Worldwide",
      "Viewers will be shown in userlist",
    ],
  },
  {
    id: 30,
    platform: "Discord",
    period: "boost",
    desc: "Boost",
    titlemain: "Discord Server Boost",
    price: 40,
    wei: "250000000000000",
    min: 1,
    max: 1,
    img: "./trovoweek.png",
    title: "Discord Invite",
    placeholder: "https://discord.gg/g94khXU",
    placeholder2: "Count",
    about: [
      "Warranty - for the entire period",
      "Server Boosts for 6 months",
      "Price for 14 Boosts",
    ],
  },
  {
    id: 31,
    platform: "Discord",
    period: "boost",
    desc: "Boost",
    titlemain: "Discord Server Boost",
    price: 25,
    wei: "250000000000000",
    min: 1,
    max: 1,
    img: "./trovoweek.png",
    title: "Discord Invite",
    placeholder: "https://discord.gg/g94khXU",
    placeholder2: "Count",
    about: [
      "Warranty - for the entire period",
      "Server Boosts for 3 months",
      "Price for 14 Boosts",
    ],
  },
];

export default function App() {
  const [checkout, setCheckout] = useState(false);
  const [order, setOrder] = useState("");
  const [platform, setPlatform] = useState("");
  const [period, setPeriod] = useState("");
  const [products, setProducts] = useState("");
  const [mini, setMini] = useState(false);
  const [payment, setPayment] = useState("cashapp");
  const [cashAppInfo, setCashAppInfo] = useState(false);
  const [pp, setPP] = useState(1);
  const [email, setEmail] = useState("");
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const [isEUCustomer, setIsEUCustomer] = useState(false);

  const [stripemodal, setStripemodal] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setStripemodal(true);
    }
  }, []);

  useEffect(() => {
    setProducts(product);
  }, []);

  useEffect(() => {
    if (payment === "paypal") {
      if (pp) {
        const fetchData = async () => {
          await fetch("/pp", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(order),
          });
        };
        //fetchData().catch(console.error);
      }
      console.log(order);
      setPP(0);
    }
  }, [payment]);

  // useEffect(() => {
  //   const checkEUUser = async () => {
  //     try {
  //       // Getting the user's IP address
  //       const response = await fetch("https://api64.ipify.org?format=json");
  //       const data = await response.json();
  //       const ipAddress = data.ip;

  //       // Requesting location data from ipinfo.io over HTTPS
  //       const locationResponse = await fetch(
  //         `https://ipinfo.io/${ipAddress}/json`
  //       );
  //       const locationData = await locationResponse.json();

  //       // Checking if the country is in the EU
  //       const eucountries = [
  //         "AT",
  //         "BE",
  //         "BG",
  //         "HR",
  //         "CY",
  //         "CZ",
  //         "DK",
  //         "EE",
  //         "FI",
  //         "FR",
  //         "DE",
  //         "GR",
  //         "HU",
  //         "IE",
  //         "IT",
  //         "LV",
  //         "LT",
  //         "LU",
  //         "MT",
  //         "NL",
  //         "PL",
  //         "PT",
  //         "RO",
  //         "SK",
  //         "SI",
  //         "ES",
  //         "SE",
  //       ];
  //       const isEU = eucountries.includes(locationData.country);

  //       // Setting the state based on the result
  //       setIsEUCustomer(isEU);
  //     } catch (error) {
  //       console.error("Error:", error);
  //     }
  //   };

  //   checkEUUser();
  // }, []);

  const closeModal = (x) => {
    setCheckout(false);
    setEmail("");
  };

  const filteredPlatform = (x) => {
    setPlatform(x);
  };

  const filteredPeriod = (x) => {
    setPeriod(x);
  };

  function cashapp(e) {
    setCashAppInfo(true);
    setMini(true);
  }

  function card(e) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let rounded = function (number) {
      return Number(number).toFixed(2);
    };
    //isEUCustomer
    if (true) {
      window.location.href = `https://${
        window.location.hostname
      }/checkout?customer_id=${email.substring(0, 20)}&invoiceNumber=inv${
        Math.floor(Math.random() * 90000) + 10000
      }&amount=${rounded(order.price)}&id=${
        order.count + " " + order.desc + " : " + order.name
      }`;
    } else {
      var raw = JSON.stringify({
        service: order.desc,
        username: order.name,
        count: order.count,
        price: rounded(order.price),
        email: email || "doge@dogehype.com",
        id: Math.floor(Math.random() * 90000) + 10000,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch("/session-initiate", requestOptions)
        .then((response) => response.text())
        .then((result) => window.location.replace(result))
        .catch((error) => console.log("error", error));
      setEmail("");
    }
  }

  const notify = (text) =>
    toast(text, {
      icon: "🔔",
      style: {
        borderRadius: "10px",
        background: "#111827",
        color: "#fff",
      },
    });

  const pull_data = (data) => {
    setCashAppInfo(false);
    setPayment("cashapp");
    if (data) {
      setOrder(data);
      onOpen();
      setPP(1);
    }
  };

  function calculateFinalPrice(price) {
    const feePercent = 2.9; // Процент комиссии PayPal
    const fixedFee = 0.3; // Фиксированная комиссия PayPal в долларах

    // Рассчитываем комиссию PayPal
    const fee = Number(price) * (feePercent / 100) + fixedFee;

    // Добавляем комиссию к исходной цене
    const finalPrice = Number(price) + fee;

    return finalPrice.toFixed(2); // Округляем до двух знаков после запятой и возвращаем результат
  }

  return (
    <div className="container mx-auto">
      <div>
        <Navbar shouldHideOnScroll isBordered maxWidth="2xl">
          <NavbarBrand>
            <strong>
              <a href="/" className="font-bold text-inherit">
                DOGEHYPE
              </a>
            </strong>
          </NavbarBrand>
          <NavbarContent className="hidden sm:flex gap-4" justify="center">
            <NavbarItem>
              <Link color="foreground" href="#faq">
                How it works
              </Link>
            </NavbarItem>
            <NavbarItem>
              <Link
                href="https://discord.gg/dogehype-842067157608693761"
                aria-current="page"
                color="foreground"
              >
                Support
              </Link>
            </NavbarItem>
          </NavbarContent>
          <NavbarContent justify="end">
            <NavbarItem>
              <Button as={Link} color="default" href="/login" radius="md">
                Sign Up
              </Button>
            </NavbarItem>
          </NavbarContent>
        </Navbar>

        <div className="relative isolate pt-40">
          <section class="pb-10">
            <div class="grid mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
              <div class="mr-auto place-self-center lg:col-span-7">
                <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-4xl lg:text-5xl dark:text-white">
                  Skyrocket Your Live Stream Reach: Genuine Engagement on
                  Autopilot
                </h1>
                <p class="max-w-2xl mb-6 font-normal text-gray-700 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-300">
                  Boost your streaming success today with DOGEHYPE! With our
                  service, you can buy{" "}
                  <strong className="text-white">Twitch</strong>{" "}
                  <strong className="text-white">Kick</strong>{" "}
                  <strong className="text-white">Chzzk.Naver</strong>{" "}
                  <strong className="text-white">Rumble</strong>{" "}
                  <strong className="text-white">BigoTV</strong> viewers and
                  followers to instantly elevate your live stream's visibility
                  and engagement. Our automated platform strategically showcases
                  your content on various external sites, including movie and
                  series portals, ensuring your streams are featured in a
                  non-skippable format at the start of each film. This method
                  not only brings real, engaged viewers directly to your stream
                  but also complies with streaming site regulations. Experience
                  genuine growth and take control of your viewer and follower
                  counts with our reliable, audience-focused strategy.
                </p>
                <a
                  href="#services"
                  className="inline-flex justify-center rounded-md items-center py-3 px-5 text-base font-medium text-center text-black bg-yellow-500 hover:bg-yellow-400 focus:ring-4 focus:ring-amber-300 dark:focus:ring-amber-900"
                >
                  Quick Order
                  <svg
                    className="ml-2 -mr-1 w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </a>
                <a
                  href="/login"
                  className="inline-flex ml-5 rounded-md justify-center items-center py-3 px-5 text-base font-medium text-center text-gray-900  border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-400 dark:hover:bg-gray-600 dark:focus:ring-gray-800"
                >
                  Dashboard{" "}
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white ml-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 18 15"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 7.5h11m0 0L8 3.786M12 7.5l-4 3.714M12 1h3c.53 0 1.04.196 1.414.544.375.348.586.82.586 1.313v9.286c0 .492-.21.965-.586 1.313A2.081 2.081 0 0 1 15 14h-3"
                    />
                  </svg>
                </a>
                <a
                  href="https://trustpilot.com/review/dogehype.biz"
                  target="_blank"
                >
                  <p class="text-yellow-500 mt-5">
                    See our reviews on 💚Trustpilot
                  </p>
                </a>
              </div>
              <div class="hidden lg:mt-0 lg:col-span-5 lg:flex">
                <svg
                  className="bounce"
                  id="dg"
                  data-name="Dogehype"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 84.25 86.44"
                >
                  <title>Dogehype</title>
                  <path
                    className="cls-1"
                    d="M791.44,191.23c2,1.23,3.77,4.06,5,5.76a39.49,39.49,0,0,0,3.41,4.16c.64.57,1.29,1.28-.47,3.48a8.26,8.26,0,0,1-6.72,2.87c-2.13-.1-6.2-6.3-5.27-10.29A10.31,10.31,0,0,1,791.44,191.23Z"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-2"
                    d="M798.53,202.88c-1.9,1.33-6.45,2.12-9.67.9,1.17,2.08,2.73,3.67,3.8,3.72a8.26,8.26,0,0,0,6.72-2.87c1-1.2,1.2-1.95,1.12-2.49C800.19,202,799.58,202.14,798.53,202.88Z"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-1"
                    d="M769.72,179.55c-2-1.17-6.84-2.11-8.75-2.51-3.24-.67-6.37-2.42-7.49-1.43s-2.29,3.73-.44,8.34,11.24,2,13.1.64S769.72,179.55,769.72,179.55Z"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-2"
                    d="M754,179a5,5,0,0,1-.47-3.41l0,0c-1.12,1-2.29,3.73-.44,8.34.89,2.23,3.52,2.79,6.22,2.6A42.85,42.85,0,0,1,754,179Z"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-3"
                    d="M733.08,249.15c-.32-2.57-3.3-5.54-4.82-6-3.94-1.85-7.39-.89-9,.72-3.41,3.41-4.17,8.84,3,13.32,10.78,7.17,27.26,4.44,27.87-4.19,1.47-6.16-12.27-18.93-20.14-9"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-1"
                    d="M744,192.27c3-4.34,13.17-19.45,34.09-12.62,16.94,5.32,21.83,27.56,13.47,38.81-.57,7.84-3.05,23.62-16.87,30.53-6.51,2.79-16.87,9.32-37.12-2.09-14.46-7.55-16.44-12.81-18.48-16.39C713,208.82,735.17,192.27,744,192.27Z"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-4"
                    d="M755.09,196.28c1.2-3.22,3.37-7,9.53-8.55,7.54-1.95,15.66,4.14,16.39,10.36.46,4-1.27,7.55-2.91,10.48-.93,1.67-3.27,2.62-3.6,3.86-2.29,8.68-12.24,27-23.26,26.52s-20.37-7.22-19.53-18.32c.53-7,3.82-14.22,12.46-19.28C747.45,198.7,752.44,198.7,755.09,196.28Z"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-4"
                    d="M774.39,182.84c1.18-.49.27-2.92-1.66-3.37-1.71-.41-1.89,1.12-1.16,2S773.62,183.17,774.39,182.84Z"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-4"
                    d="M786.65,188c.37,1.51-2,2.05-3.55.39-1.09-1.17-.67-2.2.54-2.31A3.24,3.24,0,0,1,786.65,188Z"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-5"
                    d="M771.41,184.41c-.18.56-1.7-.22-2.39-.16s-1.89.16-1.91-.28,1.39-.61,2.14-.54S771.55,184,771.41,184.41Z"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-5"
                    d="M781.86,190.24c-.43.4.92,1.46,1.16,2.1s.65,1.78,1.07,1.61,0-1.51-.42-2.16S782.19,190,781.86,190.24Z"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-2"
                    d="M770.32,185.82a8,8,0,0,1,9.35,5.83c1.31,4.73-.37,8.28-3.61,8.88-4.27.79-11.87-3.87-12.1-8.62C763.79,188.42,766.88,186.52,770.32,185.82Z"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-6"
                    d="M774.77,189c-.2.43-.78,1.27-1,1.56"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-5"
                    d="M773.45,186.43c.61-.7,4.22,1.07,4.38,2.52.13,1.15-2.41.49-3.06.07C774.46,188.82,772.7,187.29,773.45,186.43Z"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-6"
                    d="M770.53,187.25a.92.92,0,0,0-1,1.3c.41.95,1.46,1.3,3,1.6s2,1,2.91,2.08a3,3,0,0,0,2.4,1.31.86.86,0,0,0,.64-1.23"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-7"
                    d="M771.12,189.82S770,193,771.73,194a3.36,3.36,0,0,0,4.41-1.17c-.76-.68-1.56-1.73-2.21-2.21S772.09,190.18,771.12,189.82Z"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-3"
                    d="M766.06,247c.59-1.44,0-5.12-2.79-4.34a2.64,2.64,0,0,0-3.61-3.28,3.42,3.42,0,0,0-5.24-.77,3.15,3.15,0,0,0-.91,4,13.16,13.16,0,0,0-3.75,4c-.81,1.48-.2,4.33,1.38,5.9a6.59,6.59,0,0,0,5.84,1.34S763.59,252.33,766.06,247Z"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-8"
                    d="M763.27,242.61a2.31,2.31,0,0,0-1.57,1.58"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-8"
                    d="M759.66,239.33a2.78,2.78,0,0,0-1.67,1.82"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-9"
                    d="M763.88,244.81c.56.51.37,1.2-.25,2a1.18,1.18,0,0,1-1.74.32,1.4,1.4,0,0,1,.12-1.85C762.6,244.68,763.4,244.39,763.88,244.81Z"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-9"
                    d="M760.74,241.51c.52.34.51,1.31,0,2s-1.38,1.1-1.91.73a1.38,1.38,0,0,1-.11-2C759.4,241.35,760.09,241.11,760.74,241.51Z"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-9"
                    d="M756.64,239.65c.46.19.67.7.17,1.57s-1.17,1.29-1.67,1a1.39,1.39,0,0,1-.12-2A1.36,1.36,0,0,1,756.64,239.65Z"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-9"
                    d="M756.34,244.06a2.83,2.83,0,0,1,2.66,2.46c.56,2.22-.23,4.45-1.6,4.35s-2-1.74-2.61-2.3-1.68-.72-2.1-1.87a1.55,1.55,0,0,1,.43-1.93C753.66,244.21,755.3,244,756.34,244.06Z"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-3"
                    d="M731.71,230.19a8.8,8.8,0,0,0-.59-4.84c-.65-1.4-3.77-3.46-5.08-.91a2.64,2.64,0,0,0-4.86.42c-1.1-.48-3.88.32-4.13,3.31a3.15,3.15,0,0,0,2.3,3.38,11.6,11.6,0,0,0,.31,5.07c.54,1.6,3.33,3.14,5.55,3s3.31-1,4.7-3"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-8"
                    d="M726,224.44a2.32,2.32,0,0,0,.1,2.23"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-8"
                    d="M721.18,224.86a2.75,2.75,0,0,0,.2,2.46"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-9"
                    d="M728.07,225.5c.76-.07,1.13.54,1.27,1.52a1.19,1.19,0,0,1-1,1.5,1.42,1.42,0,0,1-1.27-1.36C727.1,226.34,727.43,225.56,728.07,225.5Z"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-9"
                    d="M723.52,225.55c.6-.15,1.3.52,1.45,1.3s-.14,1.76-.77,1.9a1.37,1.37,0,0,1-1.56-1.3C722.49,226.42,722.78,225.75,723.52,225.55Z"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-9"
                    d="M719.36,227.29c.45-.21,1,0,1.27.94s.15,1.74-.42,1.9a1.4,1.4,0,0,1-1.5-1.24A1.35,1.35,0,0,1,719.36,227.29Z"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-9"
                    d="M722.39,230.51a2.81,2.81,0,0,1,3.61-.27c2,1.09,3.1,3.19,2.1,4.13s-2.61.25-3.45.34-1.68.75-2.81.27a1.55,1.55,0,0,1-1.12-1.63C720.67,232.58,721.67,231.26,722.39,230.51Z"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <line
                    className="cls-8"
                    x1="23.88"
                    y1="68.66"
                    x2="20.78"
                    y2="69.42"
                  ></line>
                  <line
                    className="cls-8"
                    x1="21.81"
                    y1="70.61"
                    x2="22.76"
                    y2="67.49"
                  ></line>
                  <line
                    className="cls-8"
                    x1="23.38"
                    y1="70.21"
                    x2="21.18"
                    y2="67.92"
                  ></line>
                  <path
                    className="cls-10"
                    d="M764.19,201.11a121.8,121.8,0,0,0-14.54-6.85c-2.12,2-3.76,4.81-1.41,9.07a17.67,17.67,0,0,0,7.34,6.58c11.11,5.94,21.89,4,24.67,2.28C780.47,212.05,770.46,204.52,764.19,201.11Z"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-11"
                    d="M761.06,203.88a5.44,5.44,0,0,1-.74-.26l-.35.27.61.3c0,.08,0,.12-.06.12h-.31l-.15,0-.57-.09-.79-.24-.36-.13a1,1,0,0,1-.3-.25l-.08-.09a2,2,0,0,1-.07-.32l0-.11H758a.18.18,0,0,1,.11,0l.31.09.42-.37a1.67,1.67,0,0,1,.49-.32,1.85,1.85,0,0,1,.33,0,.17.17,0,0,1,.09.2.79.79,0,0,1-.24.37l-.39.36.27.11.85-.75.09-.18,0-.09a5.78,5.78,0,0,1,.57.12.09.09,0,0,1,0,0,.28.28,0,0,1,0,.26l-.08.13,0,.08.29.1a.62.62,0,0,1,.23.13c.07.06.07.15,0,.26S761.12,203.89,761.06,203.88Zm.62,1.73a2,2,0,0,1-.8-.7.53.53,0,0,1,.11-.79l.74-.6.18-.22.29.07a.65.65,0,0,1,.22.25.33.33,0,0,1,0,.24.67.67,0,0,1-.23.21,4.55,4.55,0,0,0,1,.34h0a2.32,2.32,0,0,1,.19.54.27.27,0,0,1-.15,0,7.94,7.94,0,0,1-1.4-.54l-.19.16c-.07.07-.09.13,0,.2a2,2,0,0,0,.69.48l.24.11a.87.87,0,0,0,.49-.2l-.28.37.12.15a.47.47,0,0,1,0,.26s0,0-.08.06-.09,0-.1,0A2.79,2.79,0,0,1,761.68,205.61Z"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-10"
                    d="M742.32,192.67s1.18-1.85,2.6-1.61,23.53,6.79,46.7,28a2.61,2.61,0,0,1-.12,2.9C789.73,220.27,761,196.53,742.32,192.67Z"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-13"
                    d="M742.54,195.15c1.36-1.57,1.07-4.39-2.49-4.45s-8.06,2.15-8.88,7c0,0,3.2-.48,5,.95a2.42,2.42,0,0,0,3.37-1.78c.68.08,1.46-.16,2.45-1.47a1.64,1.64,0,0,0,.54-.26"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-3"
                    d="M742.54,195.15c1.36-1.57,1.07-4.39-2.49-4.45a9,9,0,0,0-9,7.27"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-8"
                    d="M742.54,195.15a1.61,1.61,0,0,1-.83.43c-.45.49-1.13,1.53-2.16,1.3a2.45,2.45,0,0,1-3.37,1.78s-1.76-.86-1.47-2.34"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-8"
                    d="M741.12,192.93a1.87,1.87,0,0,1,.59,2.65"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-8"
                    d="M738.93,194.52a2.05,2.05,0,0,1,.62,2.36"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-8"
                    d="M731,198.47a9.41,9.41,0,0,1,3.63-1.9"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-3"
                    d="M775.34,230.71c-.57-.86-2.65-3.73-.59-9.84-1.17-1.16-2.21-.22-3.49-1.53a2.53,2.53,0,0,1-.36-3.11c-.94-1.82,1.08-2.82,1.08-2.82,0-2.08,2.4-2.7,8.3-.77,8.44,5.06,7.11,12.89,6.87,13.49"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-3"
                    d="M770.9,216.23a2.51,2.51,0,0,1,2.54-.32"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-3"
                    d="M772,213.41s1.75-.62,2.44-.06"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-14"
                    d="M777.87,197.25a1,1,0,1,1-1-1A1,1,0,0,1,777.87,197.25Z"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                  <path
                    className="cls-14"
                    d="M774.07,198.81c-.24.42-.65.64-.9.49s-.26-.61,0-1,.65-.64.9-.49S774.31,198.39,774.07,198.81Z"
                    transform="translate(-716.51 -175.07)"
                  ></path>
                </svg>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div>
        <p className="text-xl">Choose your platform</p>
        <RadioGroup
          label="Choose which platform you will be using your new package on"
          value={platform}
          orientation="horizontal"
          color="warning"
          onValueChange={(e) => filteredPlatform(e)}
          css={{ overflowX: "auto", color: "red" }}
          radius="none"
        >
          <Radio value="Twitch" description="Services" issquared="true">
            Twitch
          </Radio>
          <Radio value="Kick" description="Services" issquared="true">
            Kick
          </Radio>
          <Radio value="Youtube" description="Services" issquared="true">
            Youtube
          </Radio>
          <Radio value="Tiktok" description="Services" issquared="true">
            Tiktok
          </Radio>
          <Radio value="Naver" description="Services" issquared="true">
            Chzzk.naver
          </Radio>
          <Radio value="Discord" description="Services" issquared="true">
            Discord
          </Radio>
          <Radio value="Trovo" description="Services" issquared="true">
            Trovo
          </Radio>
        </RadioGroup>
        <Spacer y={5} />
        <RadioGroup
          css={{ overflowX: "auto" }}
          label="Filters"
          value={period}
          orientation="horizontal"
          color="warning"
          onValueChange={(e) => filteredPeriod(e)}
          radius="none"
        >
          <Radio value="hour" issquared="true">
            Hour
          </Radio>
          <Radio value="day" issquared="true">
            Day
          </Radio>
          <Radio value="week" issquared="true">
            Week
          </Radio>
          <Radio value="month" issquared="true">
            Month
          </Radio>
          <Radio value="followers" issquared="true">
            Followers
          </Radio>
          <Radio value="views" issquared="true">
            Views
          </Radio>
          <Radio value="likes" issquared="true">
            Likes
          </Radio>
        </RadioGroup>
        <Spacer y={10} />
        {!products ? (
          <CircularProgress aria-label="Loading..." />
        ) : (
          <div
            id="services"
            className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4"
          >
            {products
              .filter((x) => x.platform.includes(platform))
              .filter((x) => x.period.includes(period))
              .map((x) => {
                return (
                  <Form key={x.id} data={x} notify={notify} func={pull_data} />
                );
              })}
          </div>
        )}
      </div>
      <Spacer y={3} />
      <div>
        <div className="mx-auto py-24 sm:px-0 sm:py-32 lg:px-0">
          <div className="relative isolate overflow-hidden bg-zinc-900 px-6 pt-16 shadow-2xl sm: sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
            <svg
              viewBox="0 0 1024 1024"
              className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
              aria-hidden="true"
            >
              <circle
                cx="512"
                cy="512"
                r="512"
                fill="#ffc75f"
                fillOpacity="0.7"
              />
              <defs>
                <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                  <stop stopColor="#17c964" />
                  <stop offset="1" stopColor="#17c964" />
                </radialGradient>
              </defs>
            </svg>
            <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                Looking for more options or personalized services?
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                Our dashboard offers a range of customizable features including
                chat bot viewers, real-time viewer adjustments during streams,
                nickname changes, raids, and much more. Plus, we support a
                variety of social platforms. Elevate your streaming experience
                with us today!
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                <a
                  href="/login"
                  className=" bg-yellow-500 rounded-md hover:bg-yellow-400 text-black px-3.5 py-2.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                >
                  Sign Up
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://discord.gg/dogehype-842067157608693761"
                  className="text-sm font-semibold leading-6 text-white"
                >
                  Support <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
            <div className="relative mt-16 h-80 lg:mt-8">
              <img
                className="absolute left-0 top-0 w-[57rem] max-w-none  bg-white/5 ring-1 ring-white/10"
                src="https://i.imgur.com/9b4D0fN.png"
                alt="App screenshot"
                width="1824"
                height="1080"
              />
            </div>
          </div>
        </div>
      </div>
      <Spacer y={3} />
      <section id="faq" className="bg-zinc-900 px-6 pt-16 shadow-2xl ">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
            How it works
          </h2>
          <div className="grid pt-8 text-left border-t border-gray-200 dark:border-gray-700 md:grid-cols-1">
            <div>
              <div className="mb-10">
                <p className="text-gray-500 dark:text-gray-300">
                  With our service, you can swiftly attract viewers to your
                  broadcasts and climb to the top of your desired category—all
                  without breaking any streaming site rules, thanks to views
                  from real people.
                </p>
              </div>
              <div className="mb-10">
                <p className="text-gray-500 dark:text-gray-300">
                  DOGEHYPE operates ingeniously by embedding a video player
                  featuring your stream on over 10,000 websites, including
                  online cinemas, gaming portals, anime sites, and more. This
                  strategy ensures you gain views from both your target audience
                  and those closely related. Plus, you have the option to refine
                  your reach by geography or language.
                </p>
              </div>
              <div className="mb-10">
                <p className="text-gray-500 dark:text-gray-300">
                  This isn't about bot-driven scams; these are genuine users who
                  come to watch the latest episode of "Wednesday," for instance,
                  and are first greeted by your stream for 30 seconds, giving
                  you a chance to captivate them with your presence and
                  commentary.
                </p>
              </div>
            </div>
            <div>
              <div className="mb-10">
                <p className="text-gray-500 dark:text-gray-300">
                  Could these viewers become loyal fans and subscribers?
                  Absolutely. Since some viewers are logged into Twitch/YouTube
                  while watching, they're more likely to encounter your stream
                  again in their recommendations or through suggestions based on
                  their subscriptions.
                </p>
              </div>
              <div className="mb-10">
                <p className="text-gray-500 dark:text-gray-300">
                  However, it's the seasoned streamers with compelling content
                  who are most likely to convert external platform viewers into
                  followers. Newcomers, on the other hand, need to focus on
                  dominating their category's top ranks to garner more organic
                  traffic from Twitch or YouTube. The visibility difference
                  between ranking fifth and twentieth is significant.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Spacer y={20} />

      <footer class="bg-white  shadow dark:bg-zinc-900">
        <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
          <div class="sm:flex sm:items-center sm:justify-between">
            <div className="space-y-1">
              <h4 className="text-medium font-medium">Nordale OU </h4>
              <p className="text-small text-default-400">
                Masina tn 22, Kesklinna linnaosa, Harju maakond, Tallinn, 10113,
                Estonia
              </p>
            </div>
            <ul class="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
              <li>
                <a
                  href="https://discord.gg/dogehype-842067157608693761"
                  class="hover:underline me-4 md:me-6"
                >
                  Support
                </a>
              </li>
              <li>
                <a href="./privacy.pdf" class="hover:underline me-4 md:me-6">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="./terms.pdf" class="hover:underline me-4 md:me-6">
                  Terms
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/dogehypedotcom"
                  class="hover:underline me-4 md:me-6"
                >
                  Twitter
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/@dogehypecom"
                  class="hover:underline me-4 md:me-6"
                >
                  YouTube
                </a>
              </li>
            </ul>
          </div>
          <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
          <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-400">
            Payment method
          </span>
          <div className="flex justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="30"
              height="30"
              viewBox="0 0 48 48"
            >
              <path
                fill="#64dd17"
                d="M14,6h20c4.418,0,8,3.582,8,8v20c0,4.418-3.582,8-8,8H14c-4.418,0-8-3.582-8-8V14	C6,9.582,9.582,6,14,6z"
              ></path>
              <path
                fill="#fafafa"
                d="M23.056,33.933c-0.122,0-0.245-0.001-0.37-0.004c-3.612-0.088-5.98-2.312-6.781-3.198 c-0.177-0.195-0.171-0.489,0.011-0.68l1.664-1.876c0.178-0.187,0.464-0.209,0.667-0.05c0.738,0.58,2.446,2.054,4.696,2.177 c2.612,0.142,3.829-0.601,3.986-1.736c0.149-1.075-0.375-1.986-3.277-2.739c-5.185-1.345-6.115-4.37-5.796-6.897 c0.335-2.659,3.09-4.777,6.285-4.745c4.566,0.047,7.38,2.086,8.361,2.938c0.22,0.191,0.225,0.525,0.018,0.73l-1.581,1.786 c-0.165,0.164-0.422,0.195-0.617,0.068c-0.799-0.52-2.392-2.074-5.236-2.074c-1.75,0-2.816,0.668-2.927,1.541 c-0.154,1.22,0.661,2.274,3.155,2.837c5.527,1.247,6.457,4.467,5.87,7.068C30.644,31.474,27.907,33.933,23.056,33.933z"
              ></path>
              <path
                fill="#fafafa"
                d="M28.032,16.592l0.839-3.99C28.937,12.292,28.699,12,28.382,12h-3.065 c-0.236,0-0.441,0.166-0.489,0.397l-0.843,4.011L28.032,16.592z"
              ></path>
              <path
                fill="#fafafa"
                d="M20.916,31l-0.925,4.397C19.926,35.708,20.163,36,20.481,36h3.065c0.236,0,0.441-0.166,0.489-0.397 L25.003,31H20.916z"
              ></path>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="30"
              height="30"
              viewBox="0 0 48 48"
            >
              <path
                fill="#1565C0"
                d="M18.7,13.767l0.005,0.002C18.809,13.326,19.187,13,19.66,13h13.472c0.017,0,0.034-0.007,0.051-0.006C32.896,8.215,28.887,6,25.35,6H11.878c-0.474,0-0.852,0.335-0.955,0.777l-0.005-0.002L5.029,33.813l0.013,0.001c-0.014,0.064-0.039,0.125-0.039,0.194c0,0.553,0.447,0.991,1,0.991h8.071L18.7,13.767z"
              ></path>
              <path
                fill="#039BE5"
                d="M33.183,12.994c0.053,0.876-0.005,1.829-0.229,2.882c-1.281,5.995-5.912,9.115-11.635,9.115c0,0-3.47,0-4.313,0c-0.521,0-0.767,0.306-0.88,0.54l-1.74,8.049l-0.305,1.429h-0.006l-1.263,5.796l0.013,0.001c-0.014,0.064-0.039,0.125-0.039,0.194c0,0.553,0.447,1,1,1h7.333l0.013-0.01c0.472-0.007,0.847-0.344,0.945-0.788l0.018-0.015l1.812-8.416c0,0,0.126-0.803,0.97-0.803s4.178,0,4.178,0c5.723,0,10.401-3.106,11.683-9.102C42.18,16.106,37.358,13.019,33.183,12.994z"
              ></path>
              <path
                fill="#283593"
                d="M19.66,13c-0.474,0-0.852,0.326-0.955,0.769L18.7,13.767l-2.575,11.765c0.113-0.234,0.359-0.54,0.88-0.54c0.844,0,4.235,0,4.235,0c5.723,0,10.432-3.12,11.713-9.115c0.225-1.053,0.282-2.006,0.229-2.882C33.166,12.993,33.148,13,33.132,13H19.66z"
              ></path>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="30"
              height="30"
              viewBox="0 0 48 48"
            >
              <path
                fill="#1565C0"
                d="M45,35c0,2.209-1.791,4-4,4H7c-2.209,0-4-1.791-4-4V13c0-2.209,1.791-4,4-4h34c2.209,0,4,1.791,4,4V35z"
              ></path>
              <path
                fill="#FFF"
                d="M15.186 19l-2.626 7.832c0 0-.667-3.313-.733-3.729-1.495-3.411-3.701-3.221-3.701-3.221L10.726 30v-.002h3.161L18.258 19H15.186zM17.689 30L20.56 30 22.296 19 19.389 19zM38.008 19h-3.021l-4.71 11h2.852l.588-1.571h3.596L37.619 30h2.613L38.008 19zM34.513 26.328l1.563-4.157.818 4.157H34.513zM26.369 22.206c0-.606.498-1.057 1.926-1.057.928 0 1.991.674 1.991.674l.466-2.309c0 0-1.358-.515-2.691-.515-3.019 0-4.576 1.444-4.576 3.272 0 3.306 3.979 2.853 3.979 4.551 0 .291-.231.964-1.888.964-1.662 0-2.759-.609-2.759-.609l-.495 2.216c0 0 1.063.606 3.117.606 2.059 0 4.915-1.54 4.915-3.752C30.354 23.586 26.369 23.394 26.369 22.206z"
              ></path>
              <path
                fill="#FFC107"
                d="M12.212,24.945l-0.966-4.748c0,0-0.437-1.029-1.573-1.029c-1.136,0-4.44,0-4.44,0S10.894,20.84,12.212,24.945z"
              ></path>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="30"
              height="30"
              viewBox="0 0 48 48"
            >
              <path
                fill="#3F51B5"
                d="M45,35c0,2.209-1.791,4-4,4H7c-2.209,0-4-1.791-4-4V13c0-2.209,1.791-4,4-4h34c2.209,0,4,1.791,4,4V35z"
              ></path>
              <path
                fill="#FFC107"
                d="M30 14A10 10 0 1 0 30 34A10 10 0 1 0 30 14Z"
              ></path>
              <path
                fill="#FF3D00"
                d="M22.014,30c-0.464-0.617-0.863-1.284-1.176-2h5.325c0.278-0.636,0.496-1.304,0.637-2h-6.598C20.07,25.354,20,24.686,20,24h7c0-0.686-0.07-1.354-0.201-2h-6.598c0.142-0.696,0.359-1.364,0.637-2h5.325c-0.313-0.716-0.711-1.383-1.176-2h-2.973c0.437-0.58,0.93-1.122,1.481-1.595C21.747,14.909,19.481,14,17,14c-5.523,0-10,4.477-10,10s4.477,10,10,10c3.269,0,6.162-1.575,7.986-4H22.014z"
              ></path>
            </svg>
          </div>
          <Spacer y={5} />
          <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © 2024{" "}
            <a href="/" class="hover:underline">
             DOGEHYPE
            </a>
            . All Rights Reserved.
          </span>
        </div>
      </footer>
      <Modal
        backdrop={"blur"}
        isOpen={isOpen}
        onClose={closeModal}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>
                <p b id="modal-title" size={18}>
                  Payment Details
                </p>
              </ModalHeader>
              <ModalBody>
                <RadioGroup
                  value={payment}
                  onValueChange={setPayment}
                  label="Payment Method"
                  defaultValue="cashapp"
                  orientation="horizontal"
                  radius="md"
                  color="warning"
                >
                  <Radio value="cashapp" description="Pay with your CashApp ">
                    Cashapp
                  </Radio>
                  <Radio value="card" description="Pay with your Card ">
                    Card
                  </Radio>
                  <Radio value="paypal" description="Pay with your PayPal ">
                    PayPal
                  </Radio>
                </RadioGroup>
                <Spacer />
                {payment === "cashapp" && (
                  <div className="cashapp">
                    <p b size={14}>
                      HEADS UP: Enter the below note when making payment. If you
                      don't do so, <u>your payment will fail.</u>
                    </p>
                    <Spacer y={5} />
                    <Snippet color="warning" symbol="">
                      {order.count + " " + order.desc + " : " + order.name}
                    </Snippet>
                    <Spacer y={5} />
                    <Image
                      className="mx-auto"
                      removeWrapper={true}
                      width={288}
                      src="https://cash.app/qr/$Curtislegg21?size=288&margin=0"
                      alt="cashapp"
                      objectfit="cover"
                      onClick={() =>
                        window.open("https://cash.app/$Curtislegg21")
                      }
                    />
                    - OR -
                    <p>
                      <Link
                        target="_blank"
                        rel="noreferrer"
                        href="https://cash.app/$Curtislegg21"
                        color="warning"
                      >
                        Payment Link
                      </Link>
                    </p>
                    <Spacer y={5} />
                    <p b size={14}>
                      Make sure to send the exact amount.
                    </p>
                    <Spacer y={5} />
                    <Snippet color="warning">{order.price}</Snippet>
                    <Spacer y={5} />
                  </div>
                )}
                {payment === "card" && (
                  <div className="paypal">
                    <Spacer y={5} />
                    <Input
                      type="email"
                      label="Email"
                      variant="faded"
                      radius="none"
                      isRequired
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Spacer y={5} />
                    {email && (
                      <Button className="text-black font-semibold" onPress={() => card()} color="warning">
                        Pay Now
                      </Button>
                    )}
                    <Spacer y={5} />
                  </div>
                )}
                {payment === "paypal" && (
                  <div className="paypal">
                    <PayPal
                      price={order.price}
                      customData={
                        order.count + " " + order.desc + " : " + order.name
                      }
                    />
                  </div>
                )}
                {payment === "cashapp" && (
                  <Button className="text-black font-semibold" color="warning" onPress={() => cashapp(order)}>
                    I've sent money
                  </Button>
                )}
                <Spacer y={5} />
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
      <Modal isOpen={mini} onClose={setMini}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>
                <p id="modal-title" size={18}>
                  Order Info
                </p>
              </ModalHeader>
              <ModalBody>
                <div>
                  <p>
                    If the payment was successful, then we have already started
                    working on your order.
                  </p>
                  <Spacer />
                  <p id="modal-title" size={14}>
                    If you have any problems with your orders come to our{" "}
                    <Link
                      icon
                      color="primary"
                      target="_blank"
                      rel="noreferrer"
                      href="https://discord.gg/dogehype-842067157608693761"
                    >
                      discord server
                    </Link>{" "}
                    and we will definitely solve any problems.
                  </p>
                  <p>
                    <Spacer />
                    <Link
                      css={{ textDecoration: "underline" }}
                      target="_blank"
                      rel="noreferrer"
                      href="https://discord.gg/dogehype-842067157608693761"
                      color="warning"
                    >
                      My order is not working
                    </Link>
                  </p>
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>

      <Modal isOpen={stripemodal} onClose={setStripemodal}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>
                <p id="modal-title" size={18}>
                  Order Info
                </p>
              </ModalHeader>
              <ModalBody>
                <div>
                  <p>
                    Order placed! You will receive an email confirmation. We
                    have already started working on your order.
                  </p>
                  <Spacer />
                  <p id="modal-title" size={14}>
                    If you have any problems with your orders come to our{" "}
                    <Link
                      icon
                      color="primary"
                      target="_blank"
                      rel="noreferrer"
                      href="https://discord.gg/dogehype-842067157608693761"
                    >
                      discord server
                    </Link>{" "}
                    and we will definitely solve any problems.
                  </p>
                  <p>
                    <Spacer />
                    <Spacer />
                    <Link
                      css={{ textDecoration: "underline" }}
                      target="_blank"
                      rel="noreferrer"
                      href="https://discord.gg/dogehype-842067157608693761"
                      color="warning"
                    >
                      My order is not working
                    </Link>
                  </p>
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>

      <Toaster position="bottom-center" reverseOrder={false} />
    </div>
  );
}
